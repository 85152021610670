const descriptions = {
  concept:
    "In einem Beratungsgespräch wird Struktur und Inhalt besprochen. Hierbei wird ein Konzept ausgearbeitet, welches dann in einem ersten Entwurf im Webdesign umgesetzt wird",
  webdesign:
    "Steht das Konzept dann wird ein Design ausgearbeitet, welches direkt mit dem Kunden abgestimmt wird. Steht das finale Design dann geht es im nächsten Schritt weiter mit der Umsetzung.",
  programing:
    "Bei der Programmierung wird das Konzept und das daraus entstandene Design nun technisch umgesetzt. Nachdem dieser Vorgang abgeschlossen wurde ist die Qualitätsprüfungsphase erreicht. Hierbei wird die Umsetzung gründlich auf Herz und Nieren getestet, um somit Device- und Browserkompatiblität zu gewährleisten. Sind die Tests und die Qualitätsprüfungen erfolgreich, dann wird die Webseite umgehend veröffentlicht. Somit ist die Umsetzung abgeschlossen.",
  support:
    "Wird zum Webentwicklungsvertrag noch ein Supportvertrag abgeschlossen, dann wird eine Jahrespauschale festgelegt welche die Inhaltspflege der Seite und den Support bei Problemen mit der Seite regelt.",
}

export default descriptions
