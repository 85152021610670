import React from "react"
import styled from "styled-components"

import variables from "../constants/variables"

const OutlineButton = ({ title, onClick, white }) => {
  return (
    <StyledOutlineButton white={white} onClick={onClick}>
      {title}
    </StyledOutlineButton>
  )
}

const primaryColor = variables.COLORS.primaryColor

const StyledOutlineButton = styled.button`
  cursor: pointer;
  color: ${props => (props.white ? "white" : "black")};
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 5px;
  border: 2px solid ${primaryColor};
  display: inline-block;
  transition: all 0.4s ease 0s;

  :hover {
    color: #ffffff !important;
    background: ${primaryColor};
    border-color: ${primaryColor} !important;
    transition: all 0.4s ease 0s;
    outline: none;
  }
  :focus {
    outline: none;
  }
`
export default OutlineButton
