import React from "react"
import { Card, CardBody } from "reactstrap"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { isBrowser } from "react-device-detect"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

const getImages = graphql`
  query {
    html: file(relativePath: { eq: "tools/html.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    css: file(relativePath: { eq: "tools/css3.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    java: file(relativePath: { eq: "tools/javascript.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    bootstrap: file(relativePath: { eq: "tools/bootstrap.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    python: file(relativePath: { eq: "tools/python.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    django: file(relativePath: { eq: "tools/django.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    sass: file(relativePath: { eq: "tools/sass.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    react: file(relativePath: { eq: "tools/react.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    reactbootstrap: file(relativePath: { eq: "tools/react-bootstrap.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    redux: file(relativePath: { eq: "tools/redux.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    reduxsaga: file(relativePath: { eq: "tools/redux_saga.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    gatsby: file(relativePath: { eq: "tools/gatsby.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    webpack: file(relativePath: { eq: "tools/webpack.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    sql: file(relativePath: { eq: "tools/sql.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    vagrant: file(relativePath: { eq: "tools/vagrant.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    figma: file(relativePath: { eq: "tools/figma.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    gimp: file(relativePath: { eq: "tools/gimp.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    git: file(relativePath: { eq: "tools/git.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    gtm: file(relativePath: { eq: "tools/gtm.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    analytics: file(relativePath: { eq: "tools/analytics.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const ToolsSprites = ({ imageName }) => {
  const images = useStaticQuery(getImages)
  const offset = isBrowser ? 200 : 10

  return (
    <StyledCard>
      <ScrollAnimation offset={offset} animateIn="fadeIn" animateOnce>
        <CardBody className="text-center">
          <Image
            fixed={images[imageName].childImageSharp.fixed}
            alt={imageName}
          />
        </CardBody>
      </ScrollAnimation>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  margin-left: 0 !important;
  margin-right: 0 !important;
  border: 0;
`

export default ToolsSprites
