import React from "react"
import { CardDeck } from "reactstrap"
import ToolsSprites from "./ToolsSprites"

import "animate.css/animate.min.css"

const firstCardList = [
  { name: "html" },
  { name: "css" },
  { name: "java" },
  { name: "bootstrap" },
  { name: "python" },
]
const secondCardList = [
  { name: "django" },
  { name: "sass" },
  { name: "react" },
  { name: "reactbootstrap" },
  { name: "redux" },
]
const thirdCardList = [
  { name: "reduxsaga" },
  { name: "gatsby" },
  { name: "webpack" },
  { name: "sql" },
  { name: "vagrant" },
]
const fourthCardList = [
  { name: "figma" },
  { name: "gimp" },
  { name: "git" },
  { name: "gtm" },
  { name: "analytics" },
]

const ToolsDeck = () => {
  return (
    <>
      <CardDeck>
        {firstCardList.map(card => {
          return <ToolsSprites key={card.name} imageName={card.name} />
        })}
      </CardDeck>
      <CardDeck>
        {secondCardList.map(card => {
          return <ToolsSprites key={card.name} imageName={card.name} />
        })}
      </CardDeck>
      <CardDeck>
        {thirdCardList.map(card => {
          return <ToolsSprites key={card.name} imageName={card.name} />
        })}
      </CardDeck>
      <CardDeck>
        {fourthCardList.map(card => {
          return <ToolsSprites key={card.name} imageName={card.name} />
        })}
      </CardDeck>
    </>
  )
}

export default ToolsDeck
