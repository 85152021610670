import React, { useState } from "react"
import { Card } from "reactstrap"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import CardFooter from "reactstrap/lib/CardFooter"

import variables from "../../constants/variables"

import OutlineButton from "../OutlineButton"
import WayToGoModal from "../../components/WayToGo/WayToGoModal"

const getImages = graphql`
  query {
    konzeption: file(relativePath: { eq: "waytogo/planing.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    webdesign: file(relativePath: { eq: "waytogo/design.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    programmierung: file(relativePath: { eq: "waytogo/progr.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    support: file(relativePath: { eq: "waytogo/support.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const WayToGoCard = ({ title, description, imageName }) => {
  const image = useStaticQuery(getImages)
  const [modalVisible, setModalVisible] = useState(false)

  const toggleModal = () => setModalVisible(!modalVisible)

  return (
    <>
      <StyledCard>
        <Image
          fluid={image[imageName].childImageSharp.fluid}
          alt="consultation"
        />
        <CardFooter className="text-center">
          <OutlineButton title={title} onClick={toggleModal} />
        </CardFooter>
      </StyledCard>
      <WayToGoModal
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        title={title}
        description={description}
      />
    </>
  )
}

const StyledCard = styled(Card)`
  box-shadow: ${variables.SHADOWS.darkShadow};
`

export default WayToGoCard
