import React from "react"
import { Form, FormGroup, Input } from "reactstrap"
import { graphql, useStaticQuery } from "gatsby"
import { Container } from "reactstrap"
import styled from "styled-components"

import StyledHero from "../StyledHero"
import OutlineButton from "../OutlineButton"

const getBackground = graphql`
  query {
    background: file(relativePath: { eq: "contact/contact-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Contact = () => {
  const image = useStaticQuery(getBackground)
  return (
    <section id="contact">
      <StyledHero img={image.background.childImageSharp.fluid} home>
        <Container>
          <StyledForm
            action="https://formspree.io/t.heft@heftigewebseiten.de"
            method="POST"
            className=""
          >
            <FormGroup>
              <Input
                required
                type="text"
                name="vorname"
                id="vorname"
                className=""
                placeholder="Vorname"
              />
            </FormGroup>
            <FormGroup>
              <Input
                required
                type="text"
                name="nachname"
                id="nachname"
                className=""
                placeholder="Nachname"
              />
            </FormGroup>
            <FormGroup>
              <Input
                required
                type="email"
                name="email"
                id="email"
                className=""
                placeholder="Email"
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="tele"
                id="tele"
                className=""
                placeholder="Telefonnummer"
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="budget"
                id="budget"
                className=""
                placeholder="Budget"
              />
            </FormGroup>
            <FormGroup>
              <Input
                required
                type="textarea"
                name="message"
                id="message"
                placeholder="Ihre Nachricht"
              />
            </FormGroup>
            <OutlineButton title="Abschicken" white />
          </StyledForm>
        </Container>
      </StyledHero>
    </section>
  )
}

const StyledForm = styled(Form)`
  background: rgba(0, 0, 0, 0.4);
  padding: 40px;
  border-radius: 20px;

  /* IE fix */
  @media all and (-ms-high-contrast: none) {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }
`

export default Contact
