import React from "react"
import { Container } from "reactstrap"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { device } from "../../utils/breakpoints"
import { isBrowser } from "react-device-detect"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import variables from "../../constants/variables"

import Title from "../Title"
import BackgroundImage from "gatsby-background-image"
import RefCarousel from "./RefCarousel"

const getImage = graphql`
  query {
    holder: file(relativePath: { eq: "ref/dektop_holder.png" }) {
      childImageSharp {
        fixed(width: 780) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const Refs = () => {
  const image = useStaticQuery(getImage)
  return (
    <StyledSection id="refs">
      <Container className="py-5">
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <Title title="Referenzen" />
          <hr />
        </ScrollAnimation>
        <div className="d-flex justify-content-center image-container">
          <StyledBg
            fixed={image.holder.childImageSharp.fixed}
            alt="desktop holder"
          >
            <RefWrapper>
              <RefCarousel />
            </RefWrapper>
          </StyledBg>
        </div>
      </Container>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  padding-top: ${isBrowser && "100px"};
  margin-top: ${isBrowser && "-100px"};
  background: ${variables.COLORS.mainGrey};

  @media (max-width: 1024px) {
    .gatsby-image-wrapper {
      height: 100% !important;
    }
  }
`

const StyledBg = styled(BackgroundImage)`
  @media (max-width: 1200px) {
    ::before {
      background-image: none !important;
    }
  }
`
const RefWrapper = styled.div`
  @media ${device.laptop} {
    position: relative;
    top: 46px;
    left: 28px;
    max-width: 723px;
  }
`
export default Refs
