import React from "react"
import Title from "../Title"
import { Container } from "reactstrap"
import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import OutlineButton from "../OutlineButton"
import { isBrowser } from "react-device-detect"

const getpicture = graphql`
  query {
    myself: file(relativePath: { eq: "profil.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const getXpYears = () => {
  const startDate = new Date(2015, 1, 1)
  const currentDate = new Date()
  const diff = currentDate.getTime() - startDate.getTime()
  return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25))
}

const AboutMe = () => {
  const picture = useStaticQuery(getpicture)
  return (
    <StyledSection id="aboutme">
      <Container className="py-5">
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <Title title="Über mich" />
          <hr />
        </ScrollAnimation>
        <div className="d-flex flex-column flex-sm-row justify-content-around mt-5">
          <ScrollAnimation
            animateIn={isBrowser ? "fadeInUp" : "fadeIn"}
            animateOnce
          >
            <ImageWrapper className="mx-auto mb-3">
              <Image
                className="mx-auto"
                fluid={picture.myself.childImageSharp.fluid}
                alt="Profilebild von Thomas De Cicco"
              />
            </ImageWrapper>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={isBrowser ? "fadeInRight" : "fadeIn"}
            animateOnce
          >
            <div className="content-box my-auto ml-sm-5">
              <p>
                Hallo mein Name ist Thomas De Cicco und ich bin seit über {getXpYears()} Jahren
                als Software Engineer und IT-Berater in der Web- und
                Appentwicklung tätig. Wenn Sie ein neues Webprojekt planen oder
                Ihr altes Projekt schon ein bisschen in die Jahre gekommen ist,
                dann sind sie hier genau richtig.
              </p>

              <Link to="/#performances">
                <OutlineButton title="Zu den Leistungen"></OutlineButton>
              </Link>
            </div>
          </ScrollAnimation>
        </div>
      </Container>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  padding-top: ${isBrowser && "100px"};
  margin-top: ${isBrowser && "-100px"};
`

const ImageWrapper = styled.div`
  .gatsby-image-wrapper {
    min-width: 200px;
    max-width: 100px;
    img {
      border-radius: 20px;
    }
  }
`

export default AboutMe
