import React from "react"

import "../utils/smothScroll"
import "../utils/resizer"

import Layout from "../components/layout"

import Intro from "../components/Intro/Intro"
import SEO from "../components/SEO"
import AboutMe from "../components/Aboutme/Aboutme"
import Performances from "../components/Performances/Performances"
import WayToGo from "../components/WayToGo/WayToGo"
import Tools from "../components/Tools/Tools"
import Refs from "../components/Refs/Refs"
import Contact from "../components/Contact/Contact"

export default ({ path }) => {
  return (
    <Layout path={path}>
      <SEO title="Home" keywords={[`Webseiten, Webdesign`]} />
      <Intro />
      <AboutMe />
      <Performances />
      <WayToGo />
      <Tools />
      <Refs />
      <Contact />
    </Layout>
  )
}
