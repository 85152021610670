import React from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import styled from "styled-components"

import variables from "../../constants/variables"

const WayToGoModal = ({ modalVisible, toggleModal, title, description }) => {
  return (
    <Modal isOpen={modalVisible} toggle={toggleModal}>
      <StyledModalHeader toggle={toggleModal}>{title}</StyledModalHeader>
      <ModalBody>{description}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Schließen
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const StyledModalHeader = styled(ModalHeader)`
  color: white;
  background: linear-gradient(
    to right,
    ${variables.COLORS.lightBlue},
    ${variables.COLORS.primaryColor},
    ${variables.COLORS.mainYellow}
  ) !important;
`

export default WayToGoModal
