import React from "react"
import { Container } from "reactstrap"
import { graphql, useStaticQuery } from "gatsby"
import { isBrowser } from "react-device-detect"
import Image from "gatsby-image"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import StyledHero from "../StyledHero"
import Title from "../Title"
import ToolsDeck from "./ToolsDeck"

const getImages = graphql`
  query {
    tools: file(relativePath: { eq: "tools/tools.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    toolsMobile: file(relativePath: { eq: "tools/toolsMobile.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Tools = () => {
  const images = useStaticQuery(getImages)
  return (
    <section id="tools" style={{ paddingBottom: isBrowser && "100px" }}>
      <StyledHero img={images.tools.childImageSharp.fluid} />
      <Container className="py-5">
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <Title title="Werkzeug" />
          <hr />
          <div className="text text-center mb-5">
            <p>
              Wie auch jeder Handwerker, braucht auch ein Webentwickler sein
              Werkzeug. Hierbei greife ich auf modernste Technologien zurück und
              verwende diese bei der Umsetzung Ihrer Homepage. Je nach Bedarf
              nutze ich folgende Technologien bei meiner Arbeit.
            </p>
          </div>
          {isBrowser ? (
            <ToolsDeck />
          ) : (
            <Image
              fluid={images.toolsMobile.childImageSharp.fluid}
              alt="Werkzeuge"
            />
          )}
        </ScrollAnimation>
      </Container>
    </section>
  )
}

export default Tools
