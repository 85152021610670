import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import { Link } from "gatsby"
import styled, { keyframes } from "styled-components"
import { fadeInUp } from "react-animations"

import StyledHero from "../../components/StyledHero"
import Banner from "../../components/Banner"
import CustomButton from "../../components/CustomButton"

const getLogo = graphql`
  query {
    thwlogo: file(relativePath: { eq: "heftig-logo.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Intro = () => {
  const data = useStaticQuery(getLogo)
  const introTitle = `Willkommen bei ${`<strong>Heft</strong>`}ige Webseiten`
  return (
    <StyledHero home>
      <Banner
        image={
          <Image
            fluid={data.thwlogo.childImageSharp.fluid}
            alt="Heftige Webseiten logo"
          />
        }
        title={<span dangerouslySetInnerHTML={{ __html: introTitle }}></span>}
        subtitle="Ihr Partner für professionelle Webseiten"
      >
        <StyledLink to="/#aboutme">
          <CustomButton title="Für mehr klick hier" />
        </StyledLink>
      </Banner>
    </StyledHero>
  )
}

const fadeInUpAnimation = keyframes`${fadeInUp}`

const StyledLink = styled(Link)`
  animation: 3s ${fadeInUpAnimation};
`

export default Intro
