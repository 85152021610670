import React from "react"
import { Container } from "reactstrap"
import { graphql, useStaticQuery } from "gatsby"

import { CardDeck } from "reactstrap"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import StyledHero from "../StyledHero"
import Title from "../Title"
import WayToGoDeck from "../WayToGo/WayToGoDeck"
import descriptions from "./description"

const getBackground = graphql`
  query {
    waytogo: file(relativePath: { eq: "waytogo/waytogo.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const wayToGoList = [
  {
    title: "Konzeption",
    description: descriptions.concept,
    imageName: "konzeption",
  },
  {
    title: "Webdesign",
    description: descriptions.webdesign,
    imageName: "webdesign",
  },
  {
    title: "Umsetzung",
    description: descriptions.programing,
    imageName: "programmierung",
  },
  {
    title: "Support",
    description: descriptions.support,
    imageName: "support",
  },
]

const WayToGo = () => {
  const image = useStaticQuery(getBackground)
  return (
    <section id="waytogo" style={{ background: "var(--mainGrey)" }}>
      <StyledHero img={image.waytogo.childImageSharp.fluid} />
      <Container className="py-5">
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <Title title="Der Weg zur professionellen Homepage" />
          <hr />
          <div className="text-center mb-5">
            <p>
              Der Weg zu Ihrer professionellen Homepage wird über mehrere
              Schritte abgewickelt.
            </p>
          </div>
        </ScrollAnimation>
        <CardDeck>
          {wayToGoList.map(card => {
            return (
              <WayToGoDeck
                key={card.imageName}
                title={card.title}
                description={card.description}
                imageName={card.imageName}
              />
            )
          })}
        </CardDeck>
      </Container>
    </section>
  )
}

export default WayToGo
