import React from "react"
import styled from "styled-components"
import { isBrowser } from "react-device-detect"

import variables from "../constants/variables"

const CustomButton = ({ title, inverted }) => {
  return <BannerButton inverted={inverted}>{title}</BannerButton>
}

const mainWhite = variables.COLORS.mainWhite
const mainBlack = variables.COLORS.mainBlack
const buttonBlue = variables.COLORS.buttonBlue
const primaryColor = variables.COLORS.primaryColor

const BannerButton = styled.button`
  cursor: pointer;
  color: ${props => (props.inverted ? mainBlack : mainWhite)};
  text-transform: uppercase;
  text-decoration: none;
  background-color: ${buttonBlue};
  padding: ${isBrowser ? "20px" : "10px"};
  border-radius: 10px;
  border: 0;
  display: inline-block;
  transition: all 0.4s ease 0s;

  :hover {
    color: #ffffff !important;
    background: ${isBrowser ? primaryColor : buttonBlue};
    transition: all 0.4s ease 0s;
    outline: none;
  }
`

export default CustomButton
