import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { Card, CardBody } from "reactstrap"
import styled from "styled-components"
import { isBrowser } from "react-device-detect"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import variables from "../../constants/variables"

const getBackground = graphql`
  query {
    consultation: file(relativePath: { eq: "performances/consultation.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    content: file(relativePath: { eq: "performances/content.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    waytogo: file(relativePath: { eq: "waytogo/waytogo.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    responsive: file(relativePath: { eq: "performances/responsive.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cms: file(relativePath: { eq: "performances/cms2.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    seo: file(relativePath: { eq: "performances/seo.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    performance: file(relativePath: { eq: "performances/p-performance.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    design: file(relativePath: { eq: "performances/design.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    support: file(relativePath: { eq: "performances/sup.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image: file(relativePath: { eq: "performances/image.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const PerformanceCard = ({ title, background, animation }) => {
  const bg = useStaticQuery(getBackground)
  const offset = isBrowser ? 200 : 10

  return (
    <StyledCard>
      <ScrollAnimation
        animateIn={isBrowser ? animation : "fadeIn"}
        offset={offset}
        animateOnce
      >
        <BackgroundImage fluid={bg[background].childImageSharp.fluid}>
          <CardBody>
            <div className="description">{title}</div>
          </CardBody>
        </BackgroundImage>
      </ScrollAnimation>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  border: 0;
  .gatsby-image-wrapper {
    display: flex;
    align-items: center;
    min-height: 200px;
    text-align: center;
    box-shadow: ${variables.SHADOWS.darkShadow};
  }
  .description {
    background: rgba(255, 255, 255, 0.8);
    font-weight: 700;
    padding: 20px;
    border-radius: 10px;
    /* IE fix */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* IE fix end */
  }
`

export default PerformanceCard
