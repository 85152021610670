import React from "react"
import { CardDeck } from "reactstrap"

import PerformanceCard from "./PerformanceCard"

const firstCardDeck = [
  {
    title: "Konzeption und Beratung",
    background: "consultation",
    animation: "slideInLeft",
  },
  {
    title: "Erstellung eines Designs",
    background: "design",
    animation: "slideInDown",
  },
  {
    title: "Programmierung",
    background: "content",
    animation: "slideInRight",
  },
]

const secondCardDeck = [
  {
    title: "Bildbearbeitung",
    background: "image",
    animation: "slideInLeft",
  },
  {
    title: "Responsive Webdesign",
    background: "responsive",
    animation: "zoomIn",
  },
  {
    title: "CMS-System",
    background: "cms",
    animation: "slideInRight",
  },
]

const thirdCardDeck = [
  {
    title: "SEO Optimierung",
    background: "seo",
    animation: "slideInLeft",
  },
  {
    title: "Leistungsoptimierung",
    background: "performance",
    animation: "slideInUp",
  },
  {
    title: "Content Pflege",
    background: "support",
    animation: "slideInRight",
  },
]

const Deck = () => {
  return (
    <>
      <CardDeck>
        {firstCardDeck.map(card => {
          return (
            <PerformanceCard
              key={card.background}
              title={card.title}
              background={card.background}
              animation={card.animation}
            />
          )
        })}
      </CardDeck>
      <CardDeck className="mt-4">
        {secondCardDeck.map(card => {
          return (
            <PerformanceCard
              key={card.background}
              title={card.title}
              background={card.background}
              animation={card.animation}
            />
          )
        })}
      </CardDeck>
      <CardDeck className="mt-4">
        {thirdCardDeck.map(card => {
          return (
            <PerformanceCard
              key={card.background}
              title={card.title}
              background={card.background}
              animation={card.animation}
            />
          )
        })}
      </CardDeck>
    </>
  )
}

export default Deck
